/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.shouldUpdateScroll = ({ routerProps: { location } }) => {
  // Returning the id of an HTML element seems the only way
  // to scroll the site to the top after navigation.
  const { hash = "" } = location
  const normalizedHash = hash.replace("#", "").trim()
  if (normalizedHash.length > 0) {
    return normalizedHash
  }
  return "site-container"
}
